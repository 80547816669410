import React,{useState} from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import './customBreadCrumb.scss';

const CustomBreadCrumb = ({data,onClick}) =>{
    const [chosen, setChosen] = useState();
    console.log(data);
    const clickFunction = (item) =>{
        console.log(item)
        setChosen(item);
        // clickhandler(item.name)
        onClick(item.name)
    }
   
     return (
      <>
         <Breadcrumb>
            {
              data.map((bcItem,index )=>{
                return(
                   <Breadcrumb.Item href={bcItem.href}>{bcItem.title}</Breadcrumb.Item>
                )
                
              })
            }
         
       
      </Breadcrumb>
      </>
    )
}
export default CustomBreadCrumb