import React from 'react'
// import styled from 'styled-components'
// import {Col, Row,Button,Container,Card} from 'react-bootstrap';
import './statusIcon.scss';


const StatusIcon = (props) => (
  
    <div className='status-image-wrap'>
          <img src={props.icon} className="status-image" alt={props.icon} />
    </div>
   
)
export default StatusIcon;