import React from "react";
import { BrowserRouter,Route, Routes } from "react-router-dom";
import LogIn from "../layout/login";
import Dashboard from "../layout/dashboard";
import Project from "../layout/project";
import Accessmanagement from "../layout/accessManagement";
import AnypointDetails from "../layout/anypointDetails";
import AwsDetails from "../layout/awsDetails";
import Templates from "../layout/templates";
import TemplateDescription from "../layout/templateDescription";
import MultiProjectDetails from "../layout/multiProjectDetails";
import Deployment from "../layout/deployment";
import AIChat from "../layout/aiChat";

function requireAuth(nextState,
  replace) {
  if (!this.authenticated()) {
    replace('/');
  }// pseudocode - SYNCHRONOUS function (cannot be async without extra callback parameter to this function)

}
function Routing() {
  return (
    <BrowserRouter>
      <div className="routes">
        <Routes>
            <Route exact path="/" element={<LogIn />} />
            <Route exact path="/dashboard" element={<Dashboard />} onEnter={requireAuth} />
            {/* <Route exact path="/projectDetails/:projName/:projId" element={<Project />} onEnter={requireAuth} /> */}
            <Route exact path="/projectDetails/:projName/:projId" element={<MultiProjectDetails />} onEnter={requireAuth} />

            <Route exact path="/templateDescription/:templateName" element={<TemplateDescription />} onEnter={requireAuth} />
            <Route exact path="/settings" element={<Accessmanagement />} onEnter={requireAuth} />
            <Route exact path="/anypoint/:orgid/:anypointId" element={ <AnypointDetails />} onEnter={requireAuth} />
            <Route exact path="/aws/:awsId" element={ <AwsDetails />} onEnter={requireAuth} />
            <Route exact path="/templates" element={ <Templates />} onEnter={requireAuth} />
            <Route exact path="/deployment/:projName/:projId" element={ <Deployment />} onEnter={requireAuth} />
            <Route exact path="/aiChat/:projName/:projId" element={ <AIChat />} onEnter={requireAuth} />
        </Routes>

      </div>
    </BrowserRouter>
  );
}

export default Routing;
