import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import TextField from '@material-ui/core/TextField';
import CustomHeader from "../../components/header";
import PropTypes from "prop-types";
import './templates.scss';
import { Container, Col, Row, Card, ListGroup, ListGroupItem, Figure, Image, Button, Table, Modal } from 'react-bootstrap';
import Select from 'react-select';
import * as moment from "moment"
import { config } from "@fortawesome/fontawesome-svg-core";
import servicenowicon from '../../assets/icons/Servicenow-logo.png';
import salesforceicon from '../../assets/icons/SalesForce-logo.png';
import genericdbicon from '../../assets/icons/genericdb.png';
import jmetericon from '../../assets/icons/jmeter.png';
import anaplan from '../../assets/icons/anaplan.png';
import workday from '../../assets/icons/workday.png';
import kafka from '../../assets/icons/kafka.png';
import magento from '../../assets/icons/magento.png';
import azureIcon from '../../assets/icons/azure-icon.png';
import springIcon from '../../assets/icons/spring.png'
import reactIcon from '../../assets/icons/react.png'
import awsIcon from '../../assets/icons/aws.png'
import mulesoftIcon from '../../assets/icons/mulesoft.png'
import golangIcon from '../../assets/icons/golang.png'
import jenkinsIcon from '../../assets/icons/jenkins.png'
import CardContainer  from "../../components/cardContainer";
import rtfIcon from '../../assets/icons/rtf.png'
import lamda from '../../assets/icons/lamda.png';
import mongodb from '../../assets/icons/mongodb.png';
import sitecore from '../../assets/icons/sitecore.png';

import mews from '../../assets/icons/mews.png';
import ImageCard from "../../components/imageCard";
import {archetypesStack}  from "../../constants/templateConstants";
import { useNavigate } from 'react-router-dom';




export default function Templates () {
  const navigate = useNavigate()


  const [state,setState] =useState ({
    resources: [],
    resource_group_name: [],
    azure_container: [],
    azure_cluster: [],
    projects: [],
    showModal: false,
    projectStep: 1,
    searchVal:"",
    projectArchetype: ''
  } ) 
 const handleChange = event => {
   event.persist()
    setState(prevState=>({...prevState, filter: event.target.value }));
  };
  const onClick = (name) => {
    navigate(`/templateDescription/${name}` );
  }
  
    const { filter, data } = state;
    // const lowercasedFilter = filter.toLowerCase();
    const filteredData = filter?archetypesStack.filter(item => item.name.toLowerCase().includes(filter.toLowerCase())):archetypesStack;
    console.log(filteredData);
    return (
      <div className="root"  >
        <CustomHeader></CustomHeader>
        <main className="content" >
          <Container style={{ 'height': '700px', 'overflow-y': 'scroll' }}>
            <div className="template-search-wrap"  >
              <div xs={12}>
                <span className="title">Templates </span><input className="search-template" placeholder="Search templates"  value={filter} onChange={handleChange} ></input>  <button className='create-project-btn searchButon' variant="contained"> Search</button>
              </div>
              <div >
              {/* <div > */}

                 {/* <CardContainer data={filteredData} onClick={(val) => console.log(val)}></CardContainer> */}
                  <Row>
                  { filteredData.length >=1 && filteredData.map((techItem)=>{
                      return(<ImageCard data={techItem} onClick={onClick} gridVal="3"/>)
                  })}
                </Row>
              </div>
            </div>
          </Container >
        </main>
      </div>
    );
  

}
