import React  from "react";
import { Button } from 'react-bootstrap';



export function AddNewInput(props){   
    return(
    <>
     <div className="text-label-wrap">
        <label className="form-text-label">{props.res.label} (required)</label>            
    </div>
    
    <div className="text-input-wrap">
        {
            props.res.type === 'select' ? 
            
                <select className="form-input custom-select" name={props.res.stateVal} onChange={props.onChange}>
                                                             <option>{props.res.label}</option>
                                                        {
                                                            props.res&& props.res.options && props.res.options.map(opt => <option value={opt}>{opt}</option>)
                                                        }
                </select>
           :   props.res.type === 'customSelect' ? 
            
                <select className="form-input custom-select" name={props.res.stateVal} onChange={props.onChange}>
                                                             <option>{props.keyName}</option>
                                                        {
                                                            props.customInputData && props.customInputData.length && props.customInputData.map((opt)=> {
                                                                return(<option value={opt[props.keyName]}>{opt[props.keyName]}</option>) 
                                                                
                                                            } )
                                                        }
                </select> : props.res.type === 'checkbox' ? 
                 <input
                type="checkbox"
               name={props.res.stateVal}
               onChange={props.onChange}
              /> : <input name = {props.res.stateVal} type={props.res.type} className="form-imput" placeholder={props.res.label} onChange={props.onChange}></input>
           
        }
       
    </div>
    </>)
}

export function SubmitButton(props){
    return(
    <>
     <Button type="button" fullWidth
        variant="contained"
        className="submit-btn" onClick={() => props.submitResource}>Submit</Button>
    </>)
}

export function ValidationComponent(props){
    return(
        <>
        <div className="invalid-value-wrap" >
          <label  style={{colour: "red"}}>{props.message}</label>
        </div>
        </>
    )
}