import React from 'react'
import styled from 'styled-components'
import { Col, Row, Button, Container, Card } from 'react-bootstrap';
import './aiCard.scss';
import StatusIcon from '../../components/statusIcon'




const secretKeysList = ["PAT", "Token", "Anypoint Client Secret", "Anypoint Password"];
const AiCard = (props) => (
  <Col xs={12}>
    <div className={"aicardContainer " + props.theme + ' ' + props.cardType} onClick={() => props.onClick(props.id)}>
      <div className='aicardHeader'>
        <span className='aiheaderTitle'>
          <StatusIcon icon={props.logo} />

        </span>


      </div>
      <div className='aicardFooter'>
        <span className='aifooterTitle'>
          {props.description}
        </span>
      </div>

    </div>
  </Col>

)
export default AiCard;