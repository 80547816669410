import React, { useState, useEffect } from "react";
import {  useParams } from 'react-router-dom';
import CustomHeader from "../../components/header";
import './anypointDetails.scss';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Container, Modal } from 'react-bootstrap';
import "../../../node_modules/react-crud-icons/dist/css/react-crud-icons.css";
import {flexGatewayList, registerFlexGatewayList } from "../../constants/configurationPageConstants";
import {  domain } from "../../config.js";
import {getResource} from "../../utilities";
import CustomTable from "../../components/customTable";
import CustomBreadCrumb from "../../components/customBreadCrumb";
import AddResource from "../common/addresource";



function AnypointDetails(props) {
    const [state,setState]=useState({
        "rtfValidation":{}
    });
    const { anypointId } = useParams();
    const handleOpen = () => {
        console.log('Show modal')
        setState(prevState => ({ ...prevState, showModal: true }))
    }
    const handleClose = () => {
        setState(prevState => ({ ...prevState, showModal: false }))
        setState(prevState => ({ ...prevState, activeTab: "" }))
    }
    function Validation(resourceLists, validationState) {
        
        resourceLists.map((item) => {
            if (state[item.stateVal] === '' || state[item.stateVal] === undefined) {
                let validObj = state[validationState];
                validObj[item.stateVal] = false;
                setState(prevState => ({ ...prevState, [validationState]: validObj }))
            }
            else {
                let validObj = state[validationState];
                validObj[item.stateVal] = true;
                setState(prevState => ({ ...prevState, [validationState]: validObj }))

            }
        })
        console.log("valll", state.rtfValidation)
        if (Object.values(state[validationState]).every(item => item))
            return true
    }
     function postResource(postObj, url, stateVar) {
        console.log("inside Post",postObj )
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'x-access-token': localStorage.getItem('token') },
            body: JSON.stringify(postObj)
        };
        console.log("requset",requestOptions);
        fetch(domain + '/api/v1/' + url, requestOptions)
            .then(response => response.json())
            .then(data => {
             getResource(url, stateVar);
            });

    }
    const submitForm =() =>{
        handleOpen();
    }
    const submitRTF = async () => {
            var rtfObj = {
            "muleSettingsId": anypointId,
            "organizationID": state.mule.muleOrg,
            "mulePassword": state.mule.mulePassword,
            "rtfRegion": state.rtfRegion,
            "rtfVendor": state.rtfVendor,
            "rtfLicenseKey": state.rtfLicenseKey,
            "rtfName": state.rtfName,
            "rtfOrg": state.mule.muleOrgId,
            "muleUsername": state.mule.muleUsername,
            "deployTargetEnv": 
            state.deployTargetEnv,
            "targetCluster": state.targetCluster
        }
        setState(prevState => ({ ...prevState, rtfResourceCreating: true }))

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'x-access-token': localStorage.getItem('token') },
            body: JSON.stringify(rtfObj)
        };
        console.log("rtfObj",rtfObj);
        fetch(domain + '/api/v1/mule/rtf', requestOptions)
            .then(response => response.json())
            .then(data => {
                setState(prevState => ({ ...prevState, rtfResourceCreating: false }))
                handleClose()
                console.log("rtf inserted",data);
                fetchResource('mule/rtf', 'rtfData','mule');
                return data;
            });

    }

     const createFlexGateway = async() =>{
            handleClose();
            var postObj =
            {
                "ec2Id": state.instanceId,
                "envId": state.flexEnvId,
                "orgId": state.mule.muleOrgId,
                "type":state.flexType,
                "name": state.flexName,
                "muleSettingsId": anypointId,
            }
            console.log(postObj);
            handleClose()
            postResource(postObj, 'flex', 'flexGateways')
    }
     const registerFlexGateway= async () => {
            handleClose();
            let routes =[
                {
                    "rules":[]
                }
            ];
            
            for(var i=0 ;i <= state.apiPathLength ; i++){
                
                routes[0].rules.push({"path": i > 0 ?state['apiPath'+i] :state['apiPath'] })
            }
            console.log(routes);
            var postObj =
            {
                "flexGateWayId":state.flexGateways.filter(flex => flex.name === state.flexGateWayId)[0]._id,
                "appName": state.flexAppName,
                "appUrl": state.flexAppUrl,
                "apiPath": state.flexAppPath,
                "appInstance":state.flexAppInstance,
                "name": state.flexAppName,
                "routes":routes,
                "muleSettingsId": anypointId,
                "policies": [
                    state.basicAuth ?
                    {
                        "policyRef": {
                            "name":"http-basic-authentication-flex"
                        },
                        "config":{
                            "username": state.basicAuthUsername,
                            "password": state.basicAuthPassword
                        }
                    
                    } : {},
                    state.rateLimiting ?
                    {
                        "policyRef": {
                            "name": "rate-limiting-flex"
                        },
                        "config":{
                            "exposeHeaders": true,
                            "rateLimits":[{
                                "maximumRequests" :state.flexMaxRequest,
                                "timePeriodInMilliseconds" : state.flexTimePeriod
                            }],
                            "keySelector": "#[attributes.queryParams['identifier']]"
                        }
                    } : {}
                ]
            }
            console.log(postObj)
            postResource(postObj, 'flex/register', 'registeredFlexGateways')

    }
    const submitResource = async (resource) => {
        console.log(resource);
        if(resource === "rtf"){
             submitRTF()
        }else if(resource === "flex"){
            createFlexGateway();
        } else{
            registerFlexGateway();
        }
           

    }
    function onChange(event) {
        const { name, value ,checked ,type } = event.target;
        console.log(checked);
        type === "checkbox" ? setState(prevState => ({ ...prevState, [name]: checked })) : setState(prevState => ({ ...prevState, [name]: value }));
        console.log(event.target)
       
    }
    const breadCrumbData=[
        {
            "href":"/settings",
            "title":"Settings"
        },
        {
            "href":"/settings",
            "title":"Anypoint"
        }
    ]
    const fetchResource = async (item, stateVar,key) => {
        getResource(item).then(
            data =>{
                console.log(item,data)
                

                if(data){
                    if(key==="resource"){
                        console.log(stateVar,data)
                        setState(prevState => ({ ...prevState, [stateVar]: data })) 
                    }
                   else if(item === "mule/rtf"){
                            console.log("mule/rtf",data)
                            console.log("mule/rtf",anypointId)

                        let filterData = data[key].filter( dataItem => dataItem.muleSettingsId === anypointId );
                        setState(prevState => ({ ...prevState, [stateVar]: filterData })) 
                        console.log("state.rtfData",state.rtfData);

                        }
                        else if( item === "mule"){
                           let filterData = data[key].filter( dataItem => dataItem._id === anypointId )[0];
                           console.log("Filter")
                            console.log(filterData);
                            setState(prevState => ({ ...prevState, [stateVar]: filterData }))  
                        }                                           
                        else if(item === "vm/ec2"){
                            console.log("vm/ec2",data)

                            setState(prevState => ({ ...prevState, [stateVar]: data })) 
                        }
                        else{
                            console.log("flex data",item)
                            let filterData = data.filter( dataItem => dataItem.muleSettingsId === anypointId );
                            console.log()
                            setState(prevState => ({ ...prevState, [stateVar]: filterData })) 
                        }
                }
               
            }
        );
        
    }
    const updateMuleSettings = async() =>{
        if(state.mule && state.mule['mule'].length){
        let muleContent = state.mule['mule'].filter(muleItem => muleItem._id === anypointId)[0];
         setState(prevState => ({ ...prevState, muleOrg: muleContent[0].muleOrg })) 
         setState(prevState => ({ ...prevState, muleOrgId: muleContent[0].muleOrgId }))
         setState(prevState => ({ ...prevState, muleUsername: muleContent[0].muleUsername })) 
         setState(prevState => ({ ...prevState, mulePassword: muleContent[0].mulePassword })) 
        }
       
         
    }
    const RTFContent = () =>{
        if(state && state.rtfData){
            return(
                <div className="tab-content-data-wrap">
                      <button className=" create-az-btn btn btn-primary settings-add" onClick={() => submitForm()}>
                                                                        + Add RTF
                        </button>
                      <CustomTable headers={["rtfName","status","targetCluster","rtfId"]} data={state.rtfData}></CustomTable>
                     
                </div>
              
            ) 
        }
       
    }
    const FlexContent = () =>{
        if(state && state.flexGateways){
            return(
                <div className="tab-content-data-wrap">
                     <button className=" create-az-btn btn btn-primary settings-add" onClick={() => submitForm()}>
                                                                        + Add Flex Gateway
                        </button>
                      <CustomTable headers={["name","type","uuid"]} data={state.flexGateways}></CustomTable>
                      
                </div>
              
            ) 
        }
       
    }
     const RegisteredGateway = () =>{
        if(state && state.registeredGateways){
            return(
                <div className="tab-content-data-wrap">
                     <button className=" create-az-btn btn btn-primary settings-add" onClick={() => submitForm()}>
                                                                        + Register Flex Gateway
                        </button>
                      <CustomTable headers={["gatewayId","appName"]} data={state.registeredGateways}></CustomTable>
                      
                </div>
              
            ) 
        }
       
    }
    useEffect( () => {
         fetchResource('mule/rtf', 'rtfData',"mule");
         fetchResource('flex', 'flexGateways');
         fetchResource('register/flex', 'registeredGateways');
         fetchResource('azresource', 'azureResource','resource');
         fetchResource('awsresource', 'awsresources','resource');
         fetchResource('gkeresource', 'gkeresource','resource');
         fetchResource('gkecluster', 'gkecluster','resource');
         fetchResource('mule', 'mule', 'mule');
         fetchResource('vm/ec2', 'ec2List','ec2');
         updateMuleSettings()
    }, [])
    const [tab, setTab] = useState('rtf');
    const anyPointTabs=[{
        "title":"RTF",
        "event":"rtf",
        "content": RTFContent()
    },
    {
        "title":"Flex-Gateway",
        "event":"flex",
        "content": FlexContent()
    },
    {
        "title":"Registered Gateway",
        "event":"registeredflex",
        "content": RegisteredGateway()
    }
] 

console.log("state.gkecluster",state.gkecluster)
    return (
        <div className="root" >
            <CustomHeader></CustomHeader>
            <main className="content">
                <Container>
                    <CustomBreadCrumb data={breadCrumbData}></CustomBreadCrumb>
                    <Tabs
                    id="controlled-tab-example"
                    activeKey={tab}
                    onSelect={(k) => setTab(k)}
                    className="customTabs mb-3"
                    fill
                    >
                        {
                            anyPointTabs.map(tab =>{
                                return(<Tab className="customTab" eventKey={tab.event} title={tab.title}>
                                    {tab.content}
                                </Tab>)
                            })
                        }
                   
                    </Tabs>
                </Container>
                {
                    state ?  
                    <Modal show={state.showModal} onHide={() => handleClose()} className="resourceModal">
                        <AddResource onChange={onChange} submitResource={submitResource}
                            state={state} activeElement={tab}  />
                    </Modal> : null
                }
                
            </main>
        </div >
    );
}

export default AnypointDetails;