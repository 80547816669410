import React,{useState} from 'react';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import { NoSettingsFound } from '../../utilities';
import './customTable.scss';

const CustomTable = ({headers,data,onClick}) =>{
    const [chosen, setChosen] = useState();
    console.log(data);
    const clickFunction = (item) =>{
        console.log(item)
        setChosen(item);
        // clickhandler(item.name)
        onClick(item.name)
    }
   
     return (
    <>
    {
      data && data.length ?
       <Table className="customTable table" variant='light'>
         <thead className="headBg">
          <tr>
            <th>
               <Form.Check 
            type="checkbox"
            id="select-all-td"
          />
            </th>
            {headers.map(header => {
              return (
                <th scope="col" key={header}>
                  {header}
                </th>
              );
            })}
          </tr>
        </thead>
            <tbody>
            {data.map((datum, index) => {
              return (
                <tr key={datum._id}>
                  <td>
                     <Form.Check 
                        type="checkbox"
                        id={`checkbox-${datum._id}-${index}`}
                      />
                  </td>
                  {headers.map((header, index) => {
                      return (
                      <td key={index}>
                          <span>{datum[header]}</span>                      
                      </td>
                      )
                  })}
                </tr>
              );
            })}
          </tbody>
      </Table> : <NoSettingsFound message="No assets found, Please create your first asset by clicking the add button"/>
    }
     
       
      </>
    )
}
export default CustomTable