import React from 'react'
import styled from 'styled-components'
import { Col, Row, Button, Container, Card } from 'react-bootstrap';
import './chart.scss';
import { ProgressBar } from "react-bootstrap"; 
import "bootstrap/dist/css/bootstrap.min.css";




const secretKeysList = ["PAT", "Token", "AWS Access Key", "Anypoint Password", "Anypoint Client Secret"];
const Chart = (props) => (
  <Row xs={12} className={"chartContainer " + props.theme} onClick={props.onClick}>


    {
      Object.entries(props.chartData).map(([key, value]) => {

        return (<Col xs={props.gridval}>
          <span className='chartTitle'>
            {key}
          </span>
          <span className='chartValue'>
            {secretKeysList.includes(key) ? <input className="table-apssword" type="password" value={value}></input> : value}
          </span>  </Col>)
      })
    }
  </Row>

)
export default Chart;

// const MultiChart = (props) => (
//   <Row xs={12} className= {"chartContainer " + props.theme} onClick={props.onClick}>


//      {

//        Object.entries(props.chartData).map(([key,value])=>{
//         let percentage = (value== undefined)? "0%" :value.percentage
//         let bgcolour = (value == undefined)? '#ed2323':(value.status== 'error')? '#f80a0a':'#327a43'
//         console.log("Props",props)

//        return( 
//         <> 
//        <Col xs={props.gridval}>
//                   <span className='chartTitle'>
//                     {key}
//                   </span>
//      </Col>
//                    <Col xs={props.gridval}>
//                   <span className='chartValue'>
//                 <div class = "mainDiv">
//                      {/* <div class = "childDiv" style={{"width" : `${props.gridval}%`}}> */}
//                      <div class = "childDiv" style={{"width" : `${percentage}`, "backgroundColor": `${bgcolour}`}}>

//                         <span class = "text"> {percentage} </span>
//                       </div>
//                   </div>
//                   </span>
//                   </Col>
//                   </>  
//        )


//       })
//      } 
//   </Row>

//   )



const MultiChart = (props) => (

  <>
    <Row xs={12} className={"chartContainer " + props.theme} >
      <Col xs={6}>
        <span className='headerTitle'>
          {props.container}
        </span>
      </Col>
      <Col xs={3}/>

      <Col xs={3}>
        <span className='headerTitle'>
          Status
        </span>
      </Col>
      {props.Templates.map(projName => {

        const statusData = (props.status !== undefined && props.status.length !== 0)? props.status.filter(status => {
          return status.exactPjtName === props.projectName + '-' + projName;
        }): [];


        let status = statusData.pop()  
        console.log("Status",status)
        // let project= props.projectName+'-'+{projName}
        let percentage = (status=== undefined)? "0%" :status.percentage
        let bgcolour = (status === undefined)? '#ed2323':(status.status== 'error')? '#f80a0a':'#327a43'
        return (
          <>
            < Col xs={6} >
              <span className='chartValue' style={{'cursor': 'pointer'}} onClick={() => props.getProjectStatus(props.projectName+'-'+projName)}>
                {props.projectName}-{projName}
              </span>
            </Col>
            <Col xs={3}/>
            <Col xs={3}>


                  <span className='chartValue'>
                    {status == undefined?
                     <ProgressBar label ={"not started"} now = {100} variant="info"/>: (status.status == 'error')?
                     <ProgressBar striped now = {100} label = {`failed`} animated  variant = "danger" />:
                     (status.status == 'releasecompleted')?
                     <ProgressBar striped now = {100} label = {`release created`}   variant = "success" />:


                     <ProgressBar striped now = {100} label = {`in progress`} animated  variant = "success" />

                  }
                 


                                     {/* <div class = "mainDiv">
                      <div class = "childDiv" style={{"width" : `${percentage}`, "backgroundColor": `${bgcolour}`}}>

                         <span class = "text"> {percentage} </span>
                       </div>
                  </div> */}
                   </span>
                   </Col>
          </>
        )



      })
      }

    </Row>
   

  </>

)
export { MultiChart };