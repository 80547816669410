import {  domain } from "../config";
import React from "react";
import notFoundIcon from '../assets/icons/no-item-found.png';

export const  getResource= async(path)=>{

    console.log("url",domain + '/api/v1/' + path)
    const requestOptions = {
        method: 'GET',
        headers: { 'x-access-token': localStorage.getItem('token') }
    };
    console.log("Request",requestOptions)
       return fetch(domain + '/api/v1/' + path, requestOptions)
              .then(response => response.json())
            
}

export const postResource = async(postObj, path)=>{
     console.log("inside Post",postObj )
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'x-access-token': localStorage.getItem('token') },
            body: JSON.stringify(postObj)
        };
        console.log("requset",requestOptions);
        return fetch(domain + '/api/v1/' + path, requestOptions)
            .then(response => response.json())
}

export function NoSettingsFound (props){
    return(
        <>
        <div className="no-settings-found">
            <img src={notFoundIcon} alt="Not Found" />
                <span> {props.message}</span>
        </div>
        </>
    )
}
