import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

import CustomHeader from "../../components/header";
import './deployment.scss';
import { Container, Col, Row, Button, Form } from 'react-bootstrap';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useNavigate } from 'react-router-dom';
import url from "../../config.js"
import styled from 'styled-components';


const Title = styled.p`
  color: #fff;
  font-weight: 100;
  @media (max-width: 100px) {
    font-size: 0.2rem;
  }
`
const Date = styled.div`
  color: #ccc;
  font-weight: 300;
  margin: 5px 0;
  
  @media (max-width: 10px) {
    font-size: 0.8rem;
  }
`
const Description = styled.p`
  color: #fff;
  font-weight: 300;
  padding:10px;
  @media (max-width: 10px) {
    font-size: 0.75rem;
  }
`


const domain = url.domain;


function Deployment() {
  let { projId,projName } = useParams();
  const navigate = useNavigate() 

  const [state, setState] = useState({
    button:''
    
  }
  )
 
 
  

  function onChange(event) {
    const {  id } = event.target;
    setState(prevState => ({ ...prevState, button: id }));
  }
  

  const submit = () => {
    if (state.button=='manual') {
      navigate(`/projectDetails/${projName}/${projId}`);

    }
    else {
      navigate(`/AIChat/${projName}/${projId}`);
    }
  }
 
  const handleClose = () => {
    setState(prevState => ({ ...prevState, showModal: false }))
  }
  
  
  console.log("state", state)
  return (
    <div className="root" >
      <CustomHeader></CustomHeader>
      <main className="content">

        <div className="project-overview-wrap">
          <Container className="project-overview-container" className={!state.showModal ? 'show' : 'hide'}>

            <Row className="project-container-row">
              <Col xs={12} className=" projects-container">
                <Row className=" projects-header-wrap">
                  <Col xs={1}>
                    <div className="sub-header">Projects</div>
                  </Col>
                  {/* <Col xs={11}>
                    <Button className='create-project-btn' variant="contained" onClick={() => handleOpen()}> Create New</Button>
                  </Col> */}

                </Row>
                <Row className=" projects-header-wrap">
                <Col xs={2}>
                <Form className="radio-button-label">
                <Form.Check
                    inline
                    type="radio"
                    label="AI"
                    name="radioGroup"
                    id="ai"
                    onChange={onChange}
                />
                <Form.Check
                inline
                    
                    type="radio"
                    label="Manual"
                    name="radioGroup"
                    id="manual"
                    onChange={onChange}
                />
                 </Form>          
               
           
            </Col>
           
                </Row>
                <Row className="radio-button-wrap">
                <Col xs={2}>
                <Button variant="primary" size="sm" onClick={() => submit()}>
                                Submit 
                              </Button>
                              </Col>
                </Row>
                
               
              </Col>
            </Row>
          </Container>
        

        </div>
      </main>
    </div>
  );
}

export default Deployment;