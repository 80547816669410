export const useStyles = theme => ({
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    submit: {
      margin: theme.spacing(3, 0, 2)
    }
  });

export const sidemenuList = ['Anypoint', 'Github', 'Azure', 'Jenkins', 'Grafana','Bitbucket','AWS','GKE','Sonarqube','GitLab','ELK']
export const trueFalseArr= ["true", "false"]
export const orgList = [{
    label: 'Organization Name',
    stateVal: 'muleOrganization',
    type: 'text',
}];
export const azureList = [
    
    {
        label: 'Resource Group Name',
        stateVal: 'aksresourcegroup',
        type: 'text'
    },
    {
        label: 'Create Azure Cluster',
        stateVal: 'createCluster',
        type: 'select',
        options: trueFalseArr
    }
];
export const azureAccountList = [
    {
        label: 'Azure Username',
        stateVal: 'username',
        type: 'text'
    },
    {
        label: 'Azure Client Id',
        stateVal: 'azusername',
        type: 'text'
    },
    {
        label: 'Azure Service Priciple Secret',
        stateVal: 'azpassword',
        type: 'password'
    },
    {
        label: 'Azure Tenant Id',
        stateVal: 'aztenantid',
        type: 'text'
    },
    {
        label: 'Azure Devops PAT',
        stateVal: 'azDevopsPat',
        type: 'password'
    },
    {
        label: 'Devops organization',
        stateVal: 'organization',
        type: 'text'
    }
];
export const githubList = [
    {
        label: 'Git Username',
        stateVal: 'gitUsername',
        type: 'text'
    },
    {
        label: 'Git PAT',
        stateVal: 'gitPat',
        type: 'password'
    }
]
export const gitlabList = [
    {
        label: 'GitLab Username',
        stateVal: 'gitlabUsername',
        type: 'text'
    },
    {
        label: 'GitLab PAT',
        stateVal: 'gitlabPat',
        type: 'password'
    }
]
export const jenkinsList = [
    {
        label: 'Jenkins Server',
        stateVal: 'jenkinsServer',
        type: 'text'
    },
    {
        label: 'Jenkins Username',
        stateVal: 'jenkinsUsername',
        type: 'text'
    },
    {
        label: 'Jenkins Token',
        stateVal: 'jenkinsToken',
        type: 'password'
    },
];

export const muleEnvList = [
    {
        label: 'Mule ClientId',
        stateVal: 'muleClientId',
        type: 'text'
    },
    {
        label: 'Mule Client Password',
        stateVal: 'muleClientPassword',
        type: 'password'
    },
    {
        label: 'Anypoint Username',
        stateVal: 'anypointUsername',
        type: 'text'
    },
    {
        label: 'Anypoint Password',
        stateVal: 'anypointPassword',
        type: 'password'
    }
];

export const environmentList = [
    {
        label: 'Environment Name',
        stateVal: 'environmentName',
        type: 'text'
    }
]
export const vpcRequestList = [
    {
        label: 'VPC Name',
        stateVal: 'vpcName',
        type: 'text'
    },
    {
        label: 'Region ',
        stateVal: 'muleRegionName',
        type: 'text'
    },
    {
        label: 'CIDR Block ',
        stateVal: 'cidrBlockName',
        type: 'text'
    }
];
export const vpnRequestList = [

    {
        label: 'VPN Name ',
        stateVal: 'vpnName',
        type: 'text'
    },
    {
        label: 'Remote IP Address ',
        stateVal: 'remoteIpAddress',
        type: 'text'
    }
];
export const roleRequestList = [
    {
        label: 'Role Group Name',
        stateVal: 'roleGroupName',
        type: 'text'
    },
    {
        label: 'Description',
        stateVal: 'roleDescription',
        type: 'text'
    },
    {
        label: 'Role ',
        stateVal: 'muleRole',
        type: 'text'
    },
    {
        label: 'Environment ',
        stateVal: 'muleEnvironment',
        type: 'text'
    }
]
export const grafanaList = [
    {
        label: 'Grafana Server',
        stateVal: 'grafanaServer',
        type: 'text'
    },
    {
        label: 'Token',
        stateVal: 'grafanaToken',
        type: 'password'
    }
]
export const regionArr =["us-east-1","us-west-1"];
export const clusterVendorArr =["aks","eks","gke"];
export const awsRegionArr=["eu-west-1","eu-west-2","us-east-1"]
export const gkeRegionArr=["eu-west1","eu-west2","us-east1","us-west1"]
export const flexTypes =[ "local", "connected"]
export const workerSizeArr=["0.1","0.2","1","2","4","8","16"]
export const replicaSizeArr=["0.1","0.2",".5","1","1.5","2","2.5","3","3.5","4"]
export const replicasArr=["1","2","3","4","5","6","7","8"]
export const envArr=['dev','Design','sandbox','prod']



export const rtfRequestList =[
     {
        label: 'RTF Name',
        stateVal: 'rtfName',
        type: 'text'
    },
    {
        label: 'RTF Region',
        stateVal: 'rtfRegion',
        type: 'select',
        options: regionArr
    },
    {
        label: 'Cloud Provider',
        stateVal: 'rtfVendor',
        type: 'select',
        options: clusterVendorArr
    },
    {
        label: 'RTF License Key',
        stateVal: 'rtfLicenseKey',
        type: 'password'
    },
    
    {
        label: 'Deployment Target',
        stateVal: 'deployTargetEnv',
        type: 'select',
        options: envArr
    }
]
 export const bitbucketList =[
     {
        label: 'Bitbucket Username',
        stateVal: 'bitbucketUsername',
        type: 'text'
    },
    {
        label: 'Bit Bucket PAT',
        stateVal: 'bitbucketPat',
        type: 'password'
    },
    {
        label: 'Bitbucket Workspace',
        stateVal: 'bitbucketWorkspace',
        type: 'text'
    },
    {
        label: 'Bit Bucket Project',
        stateVal: 'bitbucketProject',
        type: 'password'
    }
]
 export const awsList =[
    
    {
        label: 'AWS Cluster Name',
        stateVal: 'awscluster',
        type: 'text'
    },
    {
        label: 'AWS Region',
        stateVal: 'awsregion',
        type: 'select',
        options: awsRegionArr
    }

]

export const awsAccountList =[
     {
        label: 'AWS Access Key',
        stateVal: 'awsaccesskey',
        type: 'password'
    },
    {
        label: 'AWS Acess Key Id',
        stateVal: 'awsaccesskeyid',
        type: 'text',
    }

]

export const VMEC2List =[
   
    {
        label: 'Vm Key',
        stateVal: 'vmKeyName',
        type: 'text'
    }

]
export const flexGatewayList =[
     {
        label: 'InstanceId',
        stateVal: 'instanceId',
        type: 'customSelect'
    },
    {
        label: 'Anypoint Environment Id',
        stateVal: 'flexEnvId',
        type: 'text',
    },
    {
        label: 'Flex gateway Type',
        stateVal: 'flexType',
        type: 'select',
        options: flexTypes
    },
    {
        label: 'Flex gateway Name',
        stateVal: 'flexName',
        type: 'text'
    }
]
export const registerFlexGatewayList =[
     {
        label: 'Flex gateway ID',
        stateVal: 'flexGateWayId',
        type: 'customSelect'
    },
    {
        label: 'Application name',
        stateVal: 'flexAppName',
        type: 'text',
    },
    {
        label: 'Application URL',
        stateVal: 'flexAppUrl',
        type: 'text'
    },
    {
        label: 'Application Services',
        stateVal: 'flexAppInstance',
        type: 'text'
    },
    {
        label: 'Basic Auth',
        stateVal: 'basicAuth',
        type: 'checkbox'
    },
     {
        label: 'Rate Limiting',
        stateVal: 'rateLimiting',
        type: 'checkbox'
    }

]

export const flexBasicAuth =[
     {
        label: 'Username',
        stateVal: 'basicAuthUsername',
        type: 'text'
    },
    {
        label: 'Password',
        stateVal: 'basicAuthPassword',
        type: 'text',
    }

]
export const flexRateLimiting =[
     {
        label: 'Maximum Requests',
        stateVal: 'flexMaxRequest',
        type: 'text'
    },
    {
        label: 'TimePeriod In Milliseconds',
        stateVal: 'flexTimePeriod',
        type: 'text',
    }

]
export const sonarList =[
    {
       label: 'SonarQube Server ',
       stateVal: 'sonarqubeServer',
       type: 'text'
   },
   {
       label: 'Username',
       stateVal: 'sonarUsername',
       type: 'text',
   },
   {
    label: 'Password',
    stateVal: 'sonarPassword',
    type: 'text',
  }

//   {
//     label: 'Connection Name',
//     stateVal: 'sonarConncetionName',
//     type: 'text',
//   }

]

export const gkeList =[
   
   {
    label: 'Cluster Name',
    stateVal: 'gkeClusterName',
    type: 'text'
},
{
    label: 'Region',
    stateVal: 'gkeRegion',
    type: 'select',
    options:gkeRegionArr
}

]

export const cloudHubDeployList =[
    {
       label: 'Number Of Workers',
       stateVal: 'workersNumber',
       type: 'test'
   },  
   {
       label: 'Worker Size',
       stateVal: 'workerSize',
       type: 'select',
       options: workerSizeArr
   }
]

export const cloudHub2DeployList =[
    {
       label: 'Replica Size',
       stateVal: 'replicaSize',
       type: 'select',
       options: replicaSizeArr
   },  
   {
       label: 'Replicas',
       stateVal: 'replicas',
       type: 'select',
       options: replicasArr
   }
]

export const elkList =[
    {
       label: 'IP Address',
       stateVal: 'elkIP',
       type: 'text',
   },  
   {
       label: 'Username',
       stateVal: 'elkUsername',
       type: 'text',
   },  
   {
       label: 'Password',
       stateVal: 'elkPassword',
       type: 'password',
   }
]

