import React, { useState, useEffect } from "react";
import {  useParams } from 'react-router-dom';
import CustomHeader from "../../components/header";
import './awsDetails.scss';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Container, Modal } from 'react-bootstrap';
import "../../../node_modules/react-crud-icons/dist/css/react-crud-icons.css";
import {getResource} from "../../utilities";
import CustomTable from "../../components/customTable";
import CustomBreadCrumb from "../../components/customBreadCrumb";
import AddResource from "../common/addresource";
import {  domain } from "../../config.js";



function AwsDetails(props) {
    console.log("PROPS",props)
    const [state,setState]=useState({
        "rtfValidation":{}
    });
    const { awsId } = useParams();
    const handleOpen = () => {
        console.log('Show modal')
        setState(prevState => ({ ...prevState, showModal: true }))
    }
    const handleClose = () => {
        setState(prevState => ({ ...prevState, showModal: false }))
        setState(prevState => ({ ...prevState, activeTab: "" }))
    }
    function postResource(postObj, url, stateVar) {
        console.log("inside Post",postObj )
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'x-access-token': localStorage.getItem('token') },
            body: JSON.stringify(postObj)
        };
        console.log("requset",requestOptions);
        fetch(domain + '/api/v1/' + url, requestOptions)
            .then(response => response.json())
            .then(data => {
             getResource(url, stateVar);
            });

    }
    function Validation(resourceLists, validationState) {
        
        resourceLists.map((item) => {
            if (state[item.stateVal] === '' || state[item.stateVal] === undefined) {
                let validObj = state[validationState];
                validObj[item.stateVal] = false;
                setState(prevState => ({ ...prevState, [validationState]: validObj }))
            }
            else {
                let validObj = state[validationState];
                validObj[item.stateVal] = true;
                setState(prevState => ({ ...prevState, [validationState]: validObj }))

            }
        })
        console.log("valll", state.rtfValidation)
        if (Object.values(state[validationState]).every(item => item))
            return true
    }
     
    const submitForm =() =>{
        handleOpen();
    }
    const createEKS = async () => {
            console.log("STATE IN AWS",state);
            handleClose();
            var postObj = {
                "awsaccesskey": state.awsaccount[0].awsaccesskey,
                "awsaccesskeyid": awsId,
                "location": state.awsregion,
                "awscluster": state.awscluster
            }
            console.log(postObj);
            console.log("Inside aws cluster creation")
            postResource(postObj, 'awsresource', 'awsresources')
    }

     const createEC2 = async() =>{
            handleClose();
            let awsObj= state.awsresources.filter(aws => aws._id === awsId);
            console.log(awsObj)
            var postObj =
            {
                "accessKeyId": awsId,
                "keyName": state.vmKeyName
            }
            console.log("Inside ec2 create",postObj);
            postResource(postObj, 'vm/ec2', 'vmEc2')
    }
    
    const submitResource = async (resource) => {
        console.log(resource);
        if(resource === "eks"){
             createEKS()
        }else{
             createEC2();
        }
    }
    function onChange(event) {
        const { name, value ,checked ,type } = event.target;
        console.log(checked);
        type === "checkbox" ? setState(prevState => ({ ...prevState, [name]: checked })) : setState(prevState => ({ ...prevState, [name]: value }));
        console.log(event.target)
       
    }
    const breadCrumbData=[
        {
            "href":"/settings",
            "title":"Settings"
        },
        {
            "href":"/settings",
            "title":"AWS"
        }
    ]
    const fetchResource = async (item, stateVar,key) => {
        getResource(item).then(
            data =>{
                console.log(data);
                if(data){
                    if(item == "vm/ec2"){
                        let filterData = data.filter( dataItem => dataItem.AWS_ACCESS_KEY_ID === awsId );
                        setState(prevState => ({ ...prevState, [stateVar]: filterData }))
                    } 
                    else {
                        let filterData = data.filter( dataItem => dataItem.awsaccesskeyid === awsId );
                        setState(prevState => ({ ...prevState, [stateVar]: filterData }))
                    }
                  
                }
               
            }
        );
        
    }
   
    const EKSContent = () =>{
        if(state && state.awsresources){
            return(
                <div className="tab-content-data-wrap">
                     <button className=" create-az-btn btn btn-primary settings-add" onClick={() => submitForm()}>
                                                                        + Add EKS
                        </button>
                      <CustomTable headers={["awscluster","location","awsUrl"]} data={state.awsresources}></CustomTable>
                      
                </div>
              
            ) 
        }
       
    }
     const EC2Content = () =>{
        if(state && state.ec2List){
            return(
                <div className="tab-content-data-wrap">
                     <button className=" create-az-btn btn btn-primary settings-add" onClick={() => submitForm()}>
                                                                        + Add EC2 Instance
                        </button>
                      <CustomTable headers={["INSTANCE_ID","PUBLIC_IP","REGION","VPC_ID","SUBNET_ID","IG_ID","RTB_ID"]} data={state.ec2List}></CustomTable>
                      
                </div>
              
            ) 
        }
       
    }
    useEffect( () => {
        
         fetchResource('awsaccount', 'awsaccount');
         fetchResource('awsresource', 'awsresources');
         fetchResource('vm/ec2', 'ec2List','ec2');

    }, [])
    const [tab, setTab] = useState('eks');
    const awsTabs=[{
        "title":"EKS",
        "event":"eks",
        "content": EKSContent()
    },
    {
        "title":"EC2 Instance",
        "event":"ec2",
        "content": EC2Content()
    }
] 
    return (
        <div className="root" >
            <CustomHeader></CustomHeader>
            <main className="content">
                <Container>
                    <CustomBreadCrumb data={breadCrumbData}></CustomBreadCrumb>
                    <Tabs
                    id="controlled-tab-example"
                    activeKey={tab}
                    onSelect={(k) => setTab(k)}
                    className="customTabs mb-3"
                    fill
                    >
                        {
                            awsTabs.map(tab =>{
                                return(<Tab className="customTab" eventKey={tab.event} title={tab.title}>
                                    {tab.content}
                                </Tab>)
                            })
                        }
                   
                    </Tabs>
                </Container>
                {
                    state ?  
                    <Modal show={state.showModal} onHide={() => handleClose()} className="resourceModal">
                        <AddResource onChange={onChange} submitResource={submitResource}
                            state={state} activeElement={tab}  />
                    </Modal> : null
                }
                
            </main>
        </div >
    );
}

export default AwsDetails;