import React from 'react'
import styled from 'styled-components'
import {Col, Row,Button,Container,Card} from 'react-bootstrap';
import './multiCard.scss';


const secretKeysList =["PAT","Token","Anypoint Client Secret", "Anypoint Password"];
const MultiCard = (props) => (
     <Col xs={12}>
        <div className= {"cardContainer " + props.theme + ' ' + props.cardType}  onClick={props.onClick}>
        {
        
         props.cardType !="grid" ? Object.entries(props.headerData).map(([key,value])=>{
            

          return(   <div className='cardHeader'>
                      <span className='headerTitle'>
                        {value}
                      </span>
                      <span className='headerValue'>
                        {key}
                      </span>
                      
                    </div>)
          }) : <Row className="gridCardHeader">
            {
              Object.entries(props.headerData).map(([key,value])=>{
            

          return(  
           <Col xs={6}>
             
                      <span className='headerTitle'>
                        {key}
                      </span>
                      <span className='headerValue'>
                       {secretKeysList.includes(key)? <input className="table-apssword" type="password" value={value}></input> : <>{value}</>}  
                      </span>
           </Col>  
         )
          })
            }
          </Row>
           
          }
          {
           props.cardType !="grid" ? Object.entries(props.footerData).map(([key,value])=>{

          return(    <div className='cardFooter'>
                        <span className='footerTitle'>
                          {key}
                        </span>
                        <span className='footerValue'>
                            {secretKeysList.includes(key)? <input className="table-apssword" type="password" value={value}></input> : <span>{value}</span> }  
                        </span>
          </div>)
          }) : <Row className='gridCardFooter'>
            {
              Object.entries(props.footerData).map(([key,value])=>{

          return(   <Col xs={6}>
            <div className='cardFooter'>
                        <span className='footerTitle'>
                          {key}
                        </span>
                        <span className='footerValue'>
                            {secretKeysList.includes(key)? <input className="table-apssword" type="password" value={value}></input> : <span>{value}</span> }  
                        </span>
          </div>
          </Col>)
          })
            }
          </Row>
        }
        
        </div>
  </Col>
   
)
export default MultiCard;