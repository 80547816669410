import React,{useState} from 'react'
import styled from 'styled-components'
import {Col, Row,Button,Container,Card} from 'react-bootstrap';
import './imageCard.scss';


const ImageCard = (props) => (
   // const [tempData, setTempate] = useState(props.data);
   // const [chosen, setChosen] = useState();
   //  console.log(props.data)
   //  const clickFunction = (item) =>{
   //      console.log(item)
   //      setChosen(item);
        
   //      // clickhandler(item.name)
   //      props.onClick(item.name)
   //  }

 <Col  xs={props.gridVal} className={props.className}>
     {
       props.data ? 
        <div className= "imgCardContainer " onClick={()=>props.onClick(props.data.name)}>
          <Row className="no-margin ">
            <Col className='imageContainer' xs={6}>
                 <img src={props.data.icon} class="cardImg" alt="" />
            </Col>
            <Col className='imageDescription' xs={6}>
               <span>{props.data.name}</span>
               {/* <span>{props.data.description}</span> */}
            </Col>
          </Row>
      
        </div> : null
     }
       
  </Col>
  
     )
export default ImageCard;
