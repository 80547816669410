import { Modal, Button } from 'react-bootstrap';
import * as myConst from "../../constants/configurationPageConstants";
import React, { useState, useEffect, useContext } from "react";
import { AddNewInput, SubmitButton, ValidationComponent } from "../../components/new-resource-form/index.js"

const orgList = myConst.orgList;
const azureList = myConst.azureList;
const azureAccountList= myConst.azureAccountList;
const awsAccountList= myConst.awsAccountList;
const githubList = myConst.githubList;
const jenkinsList = myConst.jenkinsList;
const muleEnvList = myConst.muleEnvList;
const grafanaList = myConst.grafanaList;
const bitbucketList = myConst.bitbucketList;
const awsList = myConst.awsList;
const vmEc2List = myConst.VMEC2List;
const flexGatewayList = myConst.flexGatewayList;
const registerFlexGatewayList = myConst.registerFlexGatewayList;
const flexBasicAuth = myConst.flexBasicAuth;
const flexRateLimiting = myConst.flexRateLimiting;
const gkeList = myConst.gkeList;
const sonarList= myConst.sonarList;
const gitlabList= myConst.gitlabList;
const elkList=myConst.elkList;





function AddNewResource(props) {
    console.log("props from accesmanagement in addnew resource",props);
    const [apiPathObject, setApiPathObject] = useState([
        {
            label: 'Api Path ',
            stateVal: 'apiPath',
            type: 'text',
        }
    ]);
    function addApiPath() {
        const apiPathObjectLen = apiPathObject.length
        let newObj = {
            label: 'Api Path ' + apiPathObjectLen,
            stateVal: 'apiPath' + apiPathObjectLen,
            type: 'text',
        }
        let newArr = [...apiPathObject, newObj]
        setApiPathObject(newArr);
        let PropsObject = {
            target: {
                name: 'apiPathLength',
                value: newArr.length,
                type: 'text',
                checked: undefined
            }
        }
        props.onChange(PropsObject)
    }
    return (
        <>
            <Modal.Header closeButton className="modal-header">
                <Modal.Title>Add New {(props.activePage === 'Github') ? 'Github Repo' : 'Resources'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                {(props.state.activePage === 'Azure' && props.state.activeTab !== 'azCluster') ? <div>
                    {
                        azureAccountList.map(res => {
                            return (
                                <div className="form-content">
                                    <AddNewInput res={res} onChange={props.onChange} />
                                    {props.state.azureValidation[res.stateVal] == false ?
                                        <ValidationComponent message='please provide value here' /> : null}
                                </div>
                            )
                        })
                    }
                    <Button type="button" fullWidth variant="contained"
                        className="submit-btn" onClick={() => props.submitResource('azaccount')}>Submit</Button>
                </div> :
                (props.state.activePage === 'Azure' && props.state.activeTab ==="azCluster") ? <div>
                    {
                        azureList.map(res => {
                            return (
                                <div className="form-content">
                                    <AddNewInput res={res} onChange={props.onChange} />
                                    {props.state.azureValidation[res.stateVal] == false ?
                                        <ValidationComponent message='please provide value here' /> : null}
                                </div>
                            )
                        })
                    }
                    <Button type="button" fullWidth variant="contained"
                        className="submit-btn" onClick={() => props.submitResource('azure')}>Submit</Button>
                </div> : props.state.activePage === 'Github' ? <div>
                    {
                        githubList.map(res => {
                            return (
                                <div className="form-content">
                                    <AddNewInput res={res} onChange={props.onChange} />
                                    {props.state.githubValidation[res.stateVal] == false ?
                                        <ValidationComponent message='please provide value here' /> : null}
                                </div>
                            )
                        })
                    }
                    <Button type="button" fullWidth variant="contained"
                        className="submit-btn" onClick={() => props.submitResource('github')}>Submit</Button>
                </div> :props.state.activePage === 'GitLab' ? <div>
                    {
                        gitlabList.map(res => {
                            return (
                                <div className="form-content">
                                    <AddNewInput res={res} onChange={props.onChange} />
                                    {props.state.gitlabValidation[res.stateVal] == false ?
                                        <ValidationComponent message='please provide value here' /> : null}
                                </div>
                            )
                        })
                    }
                    <Button type="button" fullWidth variant="contained"
                        className="submit-btn" onClick={() => props.submitResource('gitlab')}>Submit</Button>
                </div> :
                    props.state.activePage === 'GKE' ? <div>
                        {/* {
                            gkeList.map(res => {
                                return (
                                    <div className="form-content">                                       
                                        <AddNewInput res={res} onChange={props.onChange} />
                                        {props.state.gkeValidation[res.stateVal] == false ?
                                            <ValidationComponent message='please provide value here' /> : null}
                                    </div>
                                )
                            })
                        } */}
                        <div className="form-content">
                            <div className="text-label-wrap">
                                <label className="form-text-label">Service Account Json Key File (required)</label>
                                <input type="file" onChange={props.handleChange} />
                            </div>
                            
                            
                        </div>
                        <Button type="button" fullWidth variant="contained"
                            className="submit-btn" onClick={() => props.submitResource('addgkeaccount')}>Submit</Button>
                    </div> :
                        props.state.activePage === 'Jenkins' ? <div>
                            {
                                jenkinsList.map(res => {
                                    return (
                                        <div className="form-content">
                                            <AddNewInput res={res} onChange={props.onChange} />
                                            {props.state.jenkinsValidation[res.stateVal] == false ?
                                                <ValidationComponent message='please provide value here' /> : null}
                                        </div>
                                    )
                                })
                            }
                            <Button type="button" fullWidth variant="contained"
                                className="submit-btn" onClick={() => props.submitResource('jenkins')}>Submit</Button>
                        </div> : 
                        props.state.activePage === 'Sonarqube' ? <div>
                        {
                            sonarList.map(res => {
                                console.log("sonarList",res)
                                return (
                                    <div className="form-content">
                                        <AddNewInput res={res} onChange={props.onChange} />
                                        {props.state.sonarValidation[res.stateVal] == false ?
                                            <ValidationComponent message='please provide value here' /> : null}
                                    </div>
                                )
                            })
                        }
                        <Button type="button" fullWidth variant="contained"
                            className="submit-btn" onClick={() => props.submitResource('sonarqube')}>Submit</Button>
                    </div> :props.state.activePage === 'Mule' ? <div>
                            {
                                muleEnvList.map(res => {
                                    return (
                                        <div className="form-content">
                                            <AddNewInput res={res} onChange={props.onChange} />
                                            {props.state.muleValidation[res.stateVal] == false ?
                                                <ValidationComponent message='please provide value here' /> : null}
                                        </div>
                                    )
                                })
                            }
                            <Button type="button" fullWidth variant="contained"
                                className="submit-btn" onClick={() => props.submitResource('mule')}>Submit</Button>
                                </div> :props.state.activePage === 'GkeCluster' ? <div>
                            {
                                gkeList.map(res => {
                                    return (
                                        <div className="form-content">
                                            <AddNewInput res={res} onChange={props.onChange} />
                                            {props.state.gkeValidation[res.stateVal] == false ?
                                                <ValidationComponent message='please provide value here' /> : null}
                                        </div>
                                    )
                                })
                            }
                            <Button type="button" fullWidth variant="contained"
                                className="submit-btn" onClick={() => props.submitResource('gkeCluster')}>Submit</Button>

                        </div> : props.state.activePage === 'Grafana' ? <div>
                            {
                                grafanaList.map(res => {
                                    return (
                                        <div className="form-content">
                                            <AddNewInput res={res} onChange={props.onChange} />
                                            {props.state.grafanaValidation[res.stateVal] == false ?
                                                <ValidationComponent message='please provide value here' /> : null}
                                        </div>
                                    )
                                })
                            }
                            <Button type="button" fullWidth variant="contained"
                                className="submit-btn" onClick={() => props.submitResource('grafana')}>Submit</Button>
                        </div> : props.state.activePage === 'Bitbucket' ? <div>
                            {
                                bitbucketList.map(res => {
                                    return (
                                        <div className="form-content">
                                            <AddNewInput res={res} onChange={props.onChange} />
                                            {props.state.bitbucketValidation[res.stateVal] == false ?
                                                <ValidationComponent message='please provide value here' /> : null}
                                        </div>
                                    )
                                })
                            }
                            <Button type="button" fullWidth variant="contained"
                                className="submit-btn" onClick={() => props.submitResource('bitbucket')}>Submit</Button>
                        </div> : (props.state.activePage === 'AWS' && props.state.activeTab === '' || props.state.activeTab === null)  ? <div>
                            {
                                awsAccountList.map(res => {
                                    return (
                                        <div className="form-content">
                                            <AddNewInput res={res} onChange={props.onChange} />
                                            {props.state.awsValidation[res.stateVal] == false ?
                                                <ValidationComponent message='please provide value here' /> : null}
                                        </div>
                                    )
                                })
                            }
                            <Button type="button" fullWidth variant="contained"
                                className="submit-btn" onClick={() => props.submitResource('awsAccount')}>Submit</Button>
                        </div>:
                        ( props.state.activePage === 'AWS' && props.state.activeTab === 'awsCluster')? <div>
                            {
                                awsList.map(res => {
                                    return (
                                        <div className="form-content">
                                            <AddNewInput res={res} onChange={props.onChange} />
                                            {props.state.awsValidation[res.stateVal] == false ?
                                                <ValidationComponent message='please provide value here' /> : null}
                                        </div>
                                    )
                                })
                            }
                            <Button type="button" fullWidth variant="contained"
                                className="submit-btn" onClick={() => props.submitResource('aws')}>Submit</Button>
                        </div> : ( props.state.activePage === 'ELK' )? <div>
                            {
                                elkList.map(res => {
                                    return (
                                        <div className="form-content">
                                            <AddNewInput res={res} onChange={props.onChange} />
                                            {props.state.awsValidation[res.stateVal] == false ?
                                                <ValidationComponent message='please provide value here' /> : null}
                                        </div>
                                    )
                                })
                            }
                            <Button type="button" fullWidth variant="contained"
                                className="submit-btn" onClick={() => props.submitResource('elk')}>Submit</Button>
                        </div> :(props.state.activePage === 'AWS' && props.state.activeTab === 'ec2' )  ? <div>
                            {
                                vmEc2List.map(res => {
                                    return (
                                        <div className="form-content">
                                            <AddNewInput res={res} onChange={props.onChange} customInputData={props.state.ec2List} keyName="AWS_ACCESS_KEY_ID" />
                                            {props.state.vmEc2Validation[res.stateVal] == false ?
                                                <ValidationComponent message='please provide value here' /> : null}
                                        </div>
                                    )
                                })
                            }
                            <Button type="button" fullWidth variant="contained"
                                className="submit-btn" onClick={() => props.submitResource('vmec2')}>Submit</Button>
                        </div> : props.state.activePage === 'Flex Gateway' ? <div>
                            {
                                flexGatewayList.map(res => {
                                    return (
                                        <div className="form-content">
                                            {
                                                res.label === "Anypoint Organization Id" ? <AddNewInput res={res} onChange={props.onChange} customInputData={props.state.mule} keyName="muleOrg" /> : <AddNewInput res={res} onChange={props.onChange} customInputData={props.state.ec2List} keyName="INSTANCE_ID" />
                                            }
                                            {/* <AddNewInput res={res} onChange={props.onChange} customInputData={props.state.ec2List} keyName="INSTANCE_ID"/>                                          */}
                                            {props.state.flexGatewayValidation[res.stateVal] == false ?
                                                <ValidationComponent message='please provide value here' /> : null}
                                        </div>
                                    )
                                })
                            }
                            <Button type="button" fullWidth variant="contained"
                                className="submit-btn" onClick={() => props.submitResource('flexgateway')}>Submit</Button>
                        </div> : props.state.activePage === 'Register Flex Gateway' ? <div>
                            {
                                registerFlexGatewayList.map(res => {
                                    return (
                                        <div className="form-content">
                                            <AddNewInput res={res} onChange={props.onChange} customInputData={props.state.flexGateways} keyName="name" />


                                            {props.state.registerFlexGatewayValidation[res.stateVal] == false ?
                                                <ValidationComponent message='please provide value here' /> : null}
                                        </div>
                                    )
                                })


                            }
                            <div>
                                {
                                    apiPathObject.map(paths => {
                                        return (
                                            <div className="form-content">
                                                <AddNewInput res={paths} onChange={props.onChange} customInputData />


                                                {props.state.registerFlexGatewayValidation[paths.stateVal] == false ?
                                                    <ValidationComponent message='please provide value here' /> : null}
                                            </div>
                                        )
                                    })

                                }
                            </div>

                            <Button type="button" fullWidth variant="contained"
                                className="add-more-path" onClick={addApiPath}>+ Add Api path</Button>
                            <div>
                                {/* <label> Policies</label> */}
                                {props.state.basicAuth ?
                                    <div className="form-content">
                                        {flexBasicAuth.map(basres => {
                                            return (<AddNewInput res={basres} onChange={props.onChange} />)
                                        })}

                                    </div> : null
                                }
                                {props.state.rateLimiting ?
                                    <div className="form-content">
                                        {flexRateLimiting.map(basres => {
                                            return (<AddNewInput res={basres} onChange={props.onChange} />)
                                        })}

                                    </div> : null
                                }
                            </div>

                            <Button type="button" fullWidth variant="contained"
                                className="submit-btn" onClick={() => props.submitResource('registerflex')}>Submit</Button>
                        </div> : <div>
                            {
                                orgList.map(res => {
                                    return (
                                        <div className="form-content">
                                            <AddNewInput res={res} onChange={props.onChange} />
                                            {props.state.muleOrgValidation[res.stateVal] == false ?
                                                <ValidationComponent message='please provide value here' /> : null}
                                        </div>
                                    )
                                })
                            }
                            <Button type="button" fullWidth variant="contained"
                                className="submit-btn" onClick={() => props.submitResource('muleorg')}>Submit</Button>
                        </div>
                }
            </Modal.Body>
        </>
    )
}
export default AddNewResource;