import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import TextField from '@material-ui/core/TextField';
import CustomHeader from "../../components/header";
import { useLocation,useParams } from 'react-router-dom';
import PropTypes from "prop-types";
import './templateDescription.scss';
import { Container, Col, Row, Card, ListGroup, ListGroupItem, Figure, Image, Button, Table, Modal } from 'react-bootstrap';
import Select from 'react-select';
import * as moment from "moment"
import { config } from "@fortawesome/fontawesome-svg-core";
import {archetypesStack}  from "../../constants/templateConstants";
import healthapiled from '../../assets/icons/health-apiled.png';
import eduApiled from '../../assets/icons/education-apiled.png';
 




export default function TemplateDescription () {

  const { templateName } = useParams();

  // const location = useLocation();
  // const templateName = new URLSearchParams(location.templateName)

  console.log("templatename",templateName)


    
  return (
      <div className="root" >
        <CustomHeader></CustomHeader>
        <main className="content">
          <Container >
          <div className="template-search-wrap"  >

              <div xs={12}>
                <span className="title">API Led Connectivity Architecture </span>
              </div>
              <div style={{ 'height': '500px', 'overflow-y': 'scroll' }}>
              <div >
                <span className="subtitle">Objective</span>
              </div>
              <div >
                {templateName == 'Open Finance Accelerator App'?
               <span className="paragraph" ><p ><br/><br/>The Open FInance Accelerator is a comprehensive solution that provides a set of pre-built APIs specifically designed for the wealth management industry. These APIs act as a bridge, facilitating seamless integration and data exchange between different systems and applications within the wealth management ecosystem.
By utilizing the Wealth Management Accelerator, financial institutions can optimize their operations, improve collaboration, and deliver a better experience to their clients.<br/><br/> These APIs enable integration with external platforms, automate processes, and provide access to valuable financial data and insights.
One key feature of the WM Accelerator is the provision of a common API data model.<br/><br/> This data model establishes a consistent structure and format for the data used in wealth management operations. Its purpose is to ensure compatibility and interoperability across various systems and applications within the industry.
 By adopting this common data model, financial institutions can seamlessly integrate different data sources and systems, enabling smooth data exchange and enhanced data analytics. It establishes a standardized way of representing client information, investment portfolios, transactions, and other essential wealth management data elements.
By leveraging the combined power of the WM API common data model and the WM APIs accelerator, firms can unlock the full potential of their systems, improve operational efficiency, and deliver exceptional services to their clients. This technology combination enables streamlined data management, enhanced collaboration, 
and personalized wealth management solutions. Ultimately, it allows financial institutions to provide a superior experience to their clients by offering tailored and efficient wealth management services.

</p></span >:<span className="paragraph"><p ><br/><br/>The Higher Education Accelerator (HEA) is an innovative product developed by our team for Mulesoft.
   Its primary purpose is to streamline and expedite the integration process between educational systems and Salesforce Education Cloud (SEC).
   HEA facilitates the seamless integration of third-party systems like Moodle (Learning Management System), Stripe (Payments), and Okta (SSO), etc. ensuring a smooth and efficient flow of data between the systems.
    Through the utilization of HEA, educational institutions can improve their overall performance and provide an optimized operations, enhance student experiences, and foster better engagement with their constituents.</p></span>}
              </div>
              <div>
              {templateName == 'Open Finance Accelerator App'?
              <span><img src={healthapiled}  width="1000" height="800"/></span>:
              <>
               <br/><br/><br/><br/>
              <span><img src={eduApiled}  width="1000" height="800"/></span></>
              }
              </div>
              </div>
              </div>

          </Container >
        </main>
      </div>
    );
  

}
