import {  Modal, Button } from 'react-bootstrap';
import {flexGatewayList,rtfRequestList,registerFlexGatewayList,awsList,VMEC2List}  from "../../constants/configurationPageConstants";
import React, {useState} from "react";
import {AddNewInput,ValidationComponet,ValidationComponent} from "../../components/new-resource-form/index.js"
import * as myConst from "../../constants/configurationPageConstants";



function AddResource ({activeElement,onChange,state,submitResource,customData}){
    console.log("rtfRequestList",rtfRequestList)
    const flexBasicAuth = myConst.flexBasicAuth;
    const flexRateLimiting = myConst.flexRateLimiting;
     const [apiPathObject, setApiPathObject] = useState([
        {
            label: 'Api Path ',
            stateVal: 'apiPath',
            type: 'text',
        }
    ]);
    function addApiPath() {
        const apiPathObjectLen = apiPathObject.length
        let newObj = {
            label: 'Api Path ' + apiPathObjectLen,
            stateVal: 'apiPath' + apiPathObjectLen,
            type: 'text',
        }
        let newArr = [...apiPathObject, newObj]
        setApiPathObject(newArr);
        let PropsObject = {
            target: {
                name: 'apiPathLength',
                value: newArr.length,
                type: 'text',
                checked: undefined
            }
        }
        onChange(PropsObject)
    }
    return(
      <>
      <Modal.Header closeButton className="modal-header">
                                <Modal.Title>Add New {activeElement}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                { activeElement === 'rtf' ?<div>
                                    {
                                        rtfRequestList.map(res => {
                                            return (
                                                <div className="form-content">
                                                    <div className="text-label-wrap">
                                                        <label className="form-text-label">{res.label} (required)</label>
                                                    </div>
                                                    {
                                                        res.type === 'select' ?  
                                                      <div className="text-input-wrap">
                                                        <select className="form-input custom-select" name={res.stateVal} onChange={onChange}>
                                                             <option>{res.label}</option>
                                                        {
                                                            res&& res.options && res.options.map(opt => <option value={opt}>{opt}</option>)
                                                        }
                                                        </select>
                                                    </div> :
                                                   <div className="text-input-wrap">
                                                        <input type={res.type} name={res.stateVal} className="form-imput" placeholder={res.label} onChange={onChange}></input>
                                                    </div>
                                                    
                                                    }
                                                    
                                                   
                                                </div>
                                                
                                            )
                                        })
                                        
                                    }
                            
                                    <div className="form-content">
                                        <div className="text-label-wrap">
                                            <label className="form-text-label">Target Cluster (required)</label>
                                        </div>
                                        <div className="text-input-wrap">
                                            <select className="form-input custom-select" name="targetCluster" onChange={onChange}>
                                                 <option val="">Select Cluster</option>
                                                {
                                                  state.rtfVendor == 'aks' ?  state.azureResource.map(res => {
                                                         return (
                                                                <option val={res.aks_cluster_name}>{res.aks_cluster_name}</option>
                                                            )
                                                    }) :state.rtfVendor == 'gke'? state.gkecluster.map(res => {
                                                        return (
                                                               <option val={res.gkeCluster}>{res.gkeCluster}</option>
                                                           )
                                                   }): state.awsresources.map(res => {
                                                         return (
                                                                <option val={res.awscluster}>{res.awscluster}</option>
                                                            )
                                                    })
                                                }
                                            </select>

                                        </div>
                                    </div>
                                    <Button type="button"  fullWidth variant="contained"
                                        className="submit-btn" onClick={() => submitResource('rtf')}>Submit</Button>
                                </div>: activeElement === 'flex' ? <div>
                            {
                                flexGatewayList.map(res => {
                                    return (
                                        <div className="form-content">
                                            {
                                                res.label === "Anypoint Organization Id" ? <AddNewInput res={res} onChange={onChange} customInputData={state.mule} keyName="muleOrg" /> : <AddNewInput res={res} onChange={onChange} customInputData={state.ec2List} keyName="INSTANCE_ID" />
                                            }
                                            {/* <AddNewInput res={res} onChange={props.onChange} customInputData={props.state.ec2List} keyName="INSTANCE_ID"/>                                          */}
                                          
                                        </div>
                                    )
                                })
                            }
                            <Button type="button" fullWidth variant="contained"
                                className="submit-btn" onClick={() => submitResource('flex')}>Submit</Button>
                        </div>:activeElement=== 'registeredflex' ? <div>
                            {
                                registerFlexGatewayList.map(res => {
                                    return (
                                        <div className="form-content">
                                            <AddNewInput res={res} onChange={onChange} customInputData={state.flexGateways} keyName="name" />
                                        </div>
                                    )
                                })


                            }
                            <div>
                                {
                                    apiPathObject.map(paths => {
                                        return (
                                            <div className="form-content">
                                                <AddNewInput res={paths} onChange={onChange} customInputData />
                                            </div>
                                        )
                                    })

                                }
                            </div>

                            <Button type="button" fullWidth variant="contained"
                                className="add-more-path" onClick={addApiPath}>+ Add Api path</Button>
                            <div>
                                {/* <label> Policies</label> */}
                                {state.basicAuth ?
                                    <div className="form-content">
                                        {flexBasicAuth.map(basres => {
                                            return (<AddNewInput res={basres} onChange={onChange} />)
                                        })}

                                    </div> : null
                                }
                                {state.rateLimiting ?
                                    <div className="form-content">
                                        {flexRateLimiting.map(basres => {
                                            return (<AddNewInput res={basres} onChange={onChange} />)
                                        })}

                                    </div> : null
                                }
                            </div>

                            <Button type="button" fullWidth variant="contained"
                                className="submit-btn" onClick={() => submitResource('registerflex')}>Submit</Button>
                        </div>:  activeElement === 'eks' ? <div>
                            {
                                awsList.map(res => {
                                    return (
                                        <div className="form-content">
                                            <AddNewInput res={res} onChange={onChange} />
                                          
                                        </div>
                                    )
                                })
                            }
                            <Button type="button" fullWidth variant="contained"
                                className="submit-btn" onClick={() => submitResource('eks')}>Submit</Button>
                        </div> : activeElement === 'ec2' ? <div>
                            {
                                VMEC2List.map(res => {
                                    return (
                                        <div className="form-content">
                                            <AddNewInput res={res} onChange={onChange} customInputData={state.ec2List} keyName="AWS_ACCESS_KEY_ID" />
                                          
                                        </div>
                                    )
                                })
                            }
                            <Button type="button" fullWidth variant="contained"
                                className="submit-btn" onClick={() => submitResource('ec2')}>Submit</Button>
                        </div> : null}
                            </Modal.Body>

      </>
    )
}
export default AddResource;    
