import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PeopleIcon from '@material-ui/icons/People';
import TransformIcon from '@material-ui/icons/Transform';
import BugReportIcon from '@material-ui/icons/BugReport';
import BarChartIcon from '@material-ui/icons/BarChart';
import LayersIcon from '@material-ui/icons/Layers';
import AssignmentIcon from '@material-ui/icons/Assignment';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import WidgetsIcon from '@material-ui/icons/Widgets';
import GitHubIcon from '@material-ui/icons/GitHub';
import CloudCircleIcon from '@material-ui/icons/CloudCircle';
import StatusIcon from '../../components/statusIcon'
import projectIcon from '../../assets/icons/Nav-IC-ProjCent.png'
import projectActiveIcon from '../../assets/icons/Nav-IC-ProjCent-over.png'
import templateIcon from '../../assets/icons/Nav-IC-temp.png'
import templateActiveIcon from '../../assets/icons/Nav-IC-temp-over.png'
import settingsIcon from '../../assets/icons/Nav-IC-config.png'
import settingsActiveIcon from '../../assets/icons/Nav-IC-config-over.png'
import './menu.scss'
import { useLocation } from 'react-router-dom';

// import { useHistory } from 'react-router-dom';
export const MainListItems = () => {
  const location = useLocation();
  return (
    <div>
      <a href="/dashboard">
        <ListItem button className={location.pathname === '/dashboard' ? 'menu-active' : null}>
          <ListItemIcon>
            <StatusIcon icon={location.pathname === '/dashboard' ? projectActiveIcon: projectIcon}></StatusIcon>
          </ListItemIcon>
          <ListItemText primary="Project Center" />
        </ListItem>
      </a>
      <a href="/templates" >
        <ListItem button className={location.pathname === '/templates' ? 'menu-active' : null}>
          <ListItemIcon>
           <StatusIcon icon={location.pathname === '/templates' ? templateActiveIcon: templateIcon}></StatusIcon>
          </ListItemIcon>
          <ListItemText primary="Project Templates" />
        </ListItem>
      </a>

    </div>
  )

}



export const SecondaryListItems = () => {
  const location = useLocation();
  return (
    <div>
      {/* <ListSubheader inset className="sub-header">Access Management</ListSubheader> */}
      <a href="/settings">
        <ListItem button className={location.pathname === '/settings' ? 'menu-active' : null}>
          <ListItemIcon>
             <StatusIcon icon={location.pathname === '/settings' ? settingsActiveIcon: settingsIcon}></StatusIcon>
          </ListItemIcon>
          <ListItemText primary="Configurations(CaaS)" />
        </ListItem>
      </a>


    </div>
  )
};